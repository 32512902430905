<template>
  <div style="width: 100%; height: 100%; overflow-x: hidden; overflow-y: auto;">
    <div class="dashboard-widget-template" style="padding:5px;" v-loading="loadingWidgetResult">
      <report-result
        ref="reportResultTable"
        :builder-fields="data.vqb.fields"
        :builder-relations="data.vqb.relations"
        :builder-search="data.vqb.search"

        :hide-header="data.hideHeader"
        :hide-page-select="true"
        :hide-page-size="true"
        :per-page="data.perPage ? +data.perPage : null"
        :columns-width="data.columnsWidth || []"
        :font-size-header="data.fontSizeHeader"
        :font-size-body="data.fontSizeBody"
        :padding-header="data.paddingHeader"
        :padding-body="data.paddingBody"
        :color-header="data.colorHeader"
        :color-body="data.colorBody"

      ></report-result>
    </div>
  </div>
</template>

<script>
import ReportResult from "@/components/report/builder/ReportResult.vue";
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'VQBTableWidgetTemplate',
  mixins: [WidgetViewMixin],
  components: {ReportResult},
  props: {},
  mounted() {
    this.loadWidgetResult()
      .then(() => {
        this.$refs.reportResultTable.setReportData(this.widgetResult);
      })

  },
  methods: {}
}
</script>
